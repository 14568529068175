/**
 * Import main SCSS entry point to utilize Vite's built-in capabilities
 * for handling styles. This will create a separate bundle for the css.
 */
import '../scss/main.scss';
import './modules/libs';

import { importModule, componentModules, helperModules } from './modules';

document.addEventListener('DOMContentLoaded', () => {
  [...componentModules, ...helperModules].forEach(importModule);
});

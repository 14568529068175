import { Import } from './importModule';

export const fixSafariParallax: Import = {
  selector: ['.portaltype-freitag-article-article'],
  importFn: [
    async () => {
      const { fixSafariParallax } = await import(
        '@ts/helpers/fixSafariParallax'
      );
      fixSafariParallax();
    },
  ],
};

const articleModules: Import = {
  selector: ['.bo-article-text'],
  importFn: [
    async () => {
      const { articleModule } = await import('@ts/helpers');
      articleModule.parallaxTextCleanUp();
      articleModule.formatLinks();
      articleModule.behaviorTracking();
    },
  ],
};

const analytics: Import = {
  importFn: [
    async () => {
      const { analytics } = await import('@ts/helpers');

      analytics.userTracking();
      analytics.noEditorTracking();
      analytics.gaOptout();

      // Only run search analytics when search form is present on page
      if (document.querySelector<HTMLElement>('.js-search-form')) {
        analytics.searchResults();
      }
    },
  ],
};

const secretLinksDialog: Import = {
  selector: ['.js-open-dialog'],
  importFn: [
    async () => {
      const { secretLinksDialog } = await import(
        '@ts/helpers/giveaway/secretLinksDialog'
      );
      secretLinksDialog();
    },
  ],
};

const blockedIframe: Import = {
  selector: ['#newsletter-iframe'],
  importFn: [
    async () => {
      const { blockedIframe } = await import('@ts/helpers/blockedIframe');
      blockedIframe('#newsletter-iframe', '#newsletter-iframe-fallback');
    },
  ],
};

const svgCorrectColor: Import = {
  importFn: [
    async () => {
      const { svgCorrectColor } = await import('@ts/helpers/icons');
      svgCorrectColor('bookmark');
    },
  ],
};

const toggleCaption: Import = {
  selector: ['.js-toggle-caption'],
  importFn: [
    async () => {
      const { setToggleCaption } = await import('@ts/helpers/toggleCaption');
      setToggleCaption();
    },
  ],
};

const toggleDialog: Import = {
  selector: ['.js-open-dialog'],
  importFn: [
    async () => {
      const { toggleDialog } = await import('@ts/helpers/toggleDialog');
      toggleDialog('.js-open-dialog');
    },
  ],
};

const handleColorScheme: Import = {
  importFn: [
    () =>
      import('@ts/helpers/handleColorScheme').then(({ handleColorScheme }) =>
        handleColorScheme(),
      ),
  ],
};

const offCanvas: Import = {
  selector: ['.js-offcanvas-toggle'],
  importFn: [
    async () => {
      const { offCanvas } = await import('@ts/helpers/header/offCanvas');
      offCanvas();
    },
  ],
};

const collapsible: Import = {
  selector: ['.js-collapsible'],
  importFn: [
    async () => {
      const { collapsible } = await import('@ts/helpers/collapsible');
      collapsible();
    },
  ],
};

const recoTeaser: Import = {
  selector: ['.js-reco-teaser-nav-item'],
  importFn: [
    async () => {
      const { recoTeaser } = await import('@ts/helpers/recoTeaser');
      recoTeaser();
    },
  ],
};

export const placeAsidesInArticle: Import = {
  selector: ['.js-reco-teaser'],
  importFn: [
    async () => {
      const { articleModule } = await import('@ts/helpers');

      // Only call for reco teaser pleacement early on if there are no images on the article
      if (!document.querySelector<HTMLElement>('.pts-article-image')) {
        articleModule.placeRecoTeaser();
      }
    },
  ],
};

export const customTinymce: Import = {
  importFn: [
    async () => {
      const { customTinymce } = await import('@ts/helpers/tinymce/tinymce');
      customTinymce();
    },
  ],
};

const scrollXMask: Import = {
  selector: ['.js-scroll-x-mask'],
  importFn: [
    async () => {
      const { scrollXMask } = await import('@ts/helpers/scrollXMask');
      scrollXMask();
    },
  ],
};

export const showMoreClusterItems: Import = {
  selector: ['.js-show-more-cluster-items'],
  importFn: [
    async () => {
      const { showMoreClusterItems } = await import(
        '@ts/helpers/showMoreClusterItems'
      );
      showMoreClusterItems();
    },
  ],
};

const closeAboBanner: Import = {
  selector: ['.js-abo-banner'],
  importFn: [
    async () => {
      const { closeAboBanner } = await import('@ts/helpers/closeAboBanner');
      closeAboBanner('.js-abo-banner');
    },
  ],
};

export const headerOnScroll: Import = {
  selector: ['.js-header'],
  importFn: [
    () =>
      import('@ts/helpers/header/headerOnScroll').then(({ headerOnScroll }) =>
        headerOnScroll(),
      ),
  ],
};

export const placeAboBreaker: Import = {
  selector: ['.bc-abo-breaker'],
  importFn: [
    async () => {
      const { placeAboBreaker } = await import('@ts/helpers/placeAboBreaker');
      placeAboBreaker();
    },
  ],
};

export const quizzes: Import = {
  selector: ['A[quiz],DIV[quiz],A[data-quiz],DIV[data-quiz]'],
  importFn: [
    async () => {
      const { quizzes } = await import('@ts/helpers/articleEmbed/quizzes');
      quizzes();
    },
  ],
};

export const instagramEmbed: Import = {
  selector: ['.instagram-media'],
  importFn: [
    async () => {
      const { instagramEmbed } = await import(
        '@ts/helpers/articleEmbed/instagramEmbed'
      );
      instagramEmbed();
    },
  ],
};

export const twitterEmbed: Import = {
  selector: ['.instagram-media'],
  importFn: [
    async () => {
      const { twitterEmbed } = await import(
        '@ts/helpers/articleEmbed/twitterEmbed'
      );
      twitterEmbed();
    },
  ],
};

export const datawrapperResize: Import = {
  selector: [
    '.portaltype-freitag-article-article',
    '[id^="datawrapper-chart"]',
  ],
  importFn: [
    async () => {
      const { datawrapperResize } = await import(
        '@ts/helpers/articleEmbed/datawrapperResize'
      );
      datawrapperResize();
    },
  ],
};

export const involveMe: Import = {
  selector: ['.involveme_embed'],
  importFn: [
    async () => {
      const { involveMe } = await import('@ts/helpers/articleEmbed/involveMe');
      involveMe();
    },
  ],
};

export const helperModules = [
  fixSafariParallax,
  articleModules,
  analytics,
  secretLinksDialog,
  blockedIframe,
  closeAboBanner,
  collapsible,
  customTinymce,
  handleColorScheme,
  headerOnScroll,
  offCanvas,
  recoTeaser,
  placeAsidesInArticle,
  scrollXMask,
  showMoreClusterItems,
  svgCorrectColor,
  toggleCaption,
  toggleDialog,
  placeAboBreaker,
  quizzes,
  instagramEmbed,
  twitterEmbed,
  datawrapperResize,
  involveMe,
];

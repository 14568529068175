type AsyncAction = () => Promise<unknown>;

export type Import = {
  selector?: string[];
  importFn: AsyncAction[];
};

export const importModule = (module: Import) => {
  // Import module if its a Default Module - aka doesn't have a selector property
  if (module.selector === undefined) module.importFn.map(fn => fn());

  // Find the first element that matches any of the selectors
  const element = module.selector
    ?.map(selector => document.querySelector(selector))
    .find(el => el !== null);

  // If element is found import module
  if (element) {
    module.importFn.map(fn => fn());
  }
};
